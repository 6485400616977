<div mat-dialog-title class="reviwerList__card-header">
  <h3>{{title}}</h3>
  <mat-icon fontSet="material-icons-outlined" (click)="cancelMessage()">highlight_off</mat-icon>
</div>
<div mat-dialog-content>
  <div class="reviwerList__card">
    <div class="reviwerList__card-body">
      <div class="reviwerList__input-wrap">
        <p *ngIf="data.isBulkAssignment">The reviewer selected from below would be assigned <strong>{{data.orderIds.length}} selected orders</strong> from the order list.</p>
        <mat-form-field appearance="outline" *ngIf="data.isBulkAssignment && title === 'Reviewers List'">
          <mat-label>Select a Reviewer</mat-label>
          <input
            type="text"
            matInput
            [formControl]="reviewerCtrl"
            [matAutocomplete]="auto"
            placeholder="Reviewer"
            required="true"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelection($event, reviewerCtrl)">
            <mat-option *ngFor="let reviewer of filteredReviewers" [value]="reviewer">
              {{ reviewer }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint *ngIf="showReviewerError()">
            Entered reviewer is not found.
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="title === 'Escalate To'">
          <mat-label>Select Sub-status</mat-label>
          <input
            type="text"
            matInput
            [formControl]="statusCtrl"
            [matAutocomplete]="auto"
            placeholder="sub-status"
            required="true"
            (keyup)="filterSubStatusOptions(statusCtrl.value, $event)"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName.bind(this)" #autoCompleteInput>
            <mat-option *ngFor="let status of filteredSubStatus" [value]="status">
              {{ status.notification_title }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint *ngIf="showStatusError()">
            Entered sub-status is not found.
          </mat-hint>
        </mat-form-field>

      <div>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Message</mat-label>
          <textarea
            matInput
            [formControl]="msgCtrl"
            maxlength="255"
            rows="6"
            placeholder="Add your message here"
            class="findings-view__textareaaaa"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="reviwerList__actions">
  <button class="reviwerList__btn-outlined"  (click)="cancelMessage()">{{ data.secondaryBtn ? data.secondaryBtn : 'Cancel'}}</button>
  <button class="reviwerList__btn-filled" [disabled]="(!statusCtrl.value && title === 'Escalate To') || (!reviewerCtrl.value && title === 'Reviewers List')" (click)="data.isBulkAssignment ? performBulkAction() : saveReviewAssignment()">{{data.button}}</button>
</div>
