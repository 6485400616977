import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/toast.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UserConfigurationService } from 'src/app/core/services/user-configuration.service';
import { message } from 'src/app/shared/constants/alerts_messages';
import { replaceNonASCIICharacters } from 'src/app/shared/helpers/common';
import { generalErrorMessage } from 'src/app/shared/errors/error-messages';

@Component({
  selector: 'app-layout-preference',
  templateUrl: './layout-preference.component.html',
  styleUrls: ['./layout-preference.component.scss']
})
export class LayoutPreferenceComponent {
  currentUserExternalId: string;

  constructor(
    public dialogRef: MatDialogRef<LayoutPreferenceComponent>,
    private userConfig: UserConfigurationService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastService
  ) {}

  ngOnInit(): void {
    this.authService.getCurrentUser().then((user) => {
      this.currentUserExternalId = user.uid;
    });
  }

  submit(): void {
    const payload = {
      is_review_layout_right: this.data.isRightSidePreference
    }
    this.userConfig.updateLayoutPreference(this.data.currentUserId, payload)
    .then(() => {
      this.setUpdatedConfigurationsInLS();
    })
    .catch((error) => {
      this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail : generalErrorMessage);
    })
  }

  setUpdatedConfigurationsInLS(): void {
    this.userConfig.getUserConfigurations(this.currentUserExternalId)
    .then((configurations) => {
        if (configurations) {
          localStorage.setItem('configurations', btoa(replaceNonASCIICharacters(JSON.stringify(configurations))));
        }
      this.dialogRef.close(true);
      this.toastr.success(message.layoutPreference);
    });
  }
}
